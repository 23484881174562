<template>
	<div class="pvhFactory">
		<basic-container>
			<el-form  :model="ruleForm"  :rules="rules" ref="ruleForm" label-width="150px">
				<el-row>
					<el-col :span="12" :sm="24" :md="12">
						<el-form-item :label="$t('TOKEN NAME')+' : '"  prop="tokenName">
							<el-input v-model="ruleForm.tokenName" :placeholder="$t('PLEASE ENTER THE TOKEN NAME')" style="width: calc(100% - 115px);"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12" :sm="24" :md="12">
						<el-form-item :label="$t('EXPIRATION TIME')+' : '">
							<el-date-picker v-model="ruleForm.time" style="width: calc(100% - 115px);"
											type="date"
											:placeholder="$t('SELECT DATA')">
							</el-date-picker>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24" :sm="24" :md="24">
						<el-form-item :label="$t('JURISDICTION')+' : '"  prop="checkedKeys">
							<div style="marginTop: 20px;display: flex;">
								<el-tree
										 v-model="ruleForm.checkedKeys"
										 :data="data"
										 show-checkbox
										 node-key="id"
										 ref="tree"
										 highlight-current
										 @check="clickCheck"
										 @setCheckedKeys="setCheckedKeys"
										 @node-collapse="nodeCollapse"
										 slot="reference"
										 :props="defaultProps">
								</el-tree>
							</div>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24" :sm="24" :md="24">
						<el-form-item :label="$t('TOKEN VALUE')+' : '">
							<span style="background: #e4e7ed;">{{tokenId}}</span>
						</el-form-item>
					</el-col>
				</el-row>
				<div style=" text-align: center;padding: 20px 0;">
					<el-button type="primary" @click="getToken('ruleForm')">{{this.$t('GENERATE TOKEN')}}</el-button>
				</div>
			</el-form>
			<avue-crud :option="tableOpt" :data="tableData" :row-style="rowStyle" :page.sync="page" @current-change="currentChange">
				<template slot-scope="{row,index,size,type}" slot="menu">
					<el-button icon="el-icon-delete-solid" type="text" size="medium" :title="$t('Delete')" @click="del(row)"></el-button>
				</template>
			</avue-crud>




			<!--<div style="marginLeft: 500px;display:none">{{check.label}}</div>
			<el-popover style="marginTop:40px;marginLeft:-120px;display:none"
						v-model="visity"
						placement="right"
						width="100"
						:title="$t('DATA PERMISSIONS')">
				<el-checkbox-group v-model="checkedCities" @change="checkRow">
					<el-checkbox v-for="city in cities" :label="city.id" :key="city.id">{{city.name}}</el-checkbox>
				</el-checkbox-group>
			</el-popover>
			<el-popover style="marginTop: 180px;display:none"
						v-model="visity"
						placement="right"
						width="100"
						:title="$t('PERMISSIONS LEVEL')">
				<el-checkbox-group v-model="ckList.checkList" @change="e => changeCheckbox(e, ckList.radios)">
					<el-checkbox v-for="value in ckList.radios" :value="value.id" :label="value.id" :key="value.id" :disabled="ckList.checkList.includes(ckList.radios[ckList.radios.length - 1].id) && value.id !== ckList.radios[ckList.radios.length - 1].id">{{value.name}}</el-checkbox>
					<el-button @click="save" size="small" style="" type="primary">{{$t('SAVE')}}</el-button>
				</el-checkbox-group>-->
			<!-- <el-radio v-model="radios" :label="3">授予</el-radio>
			<el-radio v-model="radios" :label="6">拒绝</el-radio>
			<el-radio v-model="radios" :label="9">拥有</el-radio> -->
			<!--</el-popover>-->
			<!-- 表结构 -->
			<!--<el-table :data="tableData"
					  :default-expand-all="false"
					  border
					  style="width: 55%">
				<el-table-column prop="tokenName"
								 :label="this.$t('TOKEN NAME')"
								 width="180">
				</el-table-column>
				<el-table-column prop="expireTime"
								 :label="this.$t('EXPIRATION TIME')"
								 width="180">
				</el-table-column>
				<el-table-column prop="createTime"
								 :label="this.$t('CREATION TIME')"
								 width="240">
				</el-table-column>
				<el-table-column :label="this.$t('OPERATION')">
					<template slot-scope="scope">
						<el-button size="mini"
								   @click.prevent="handleEdit(scope.$index, scope.row)">{{$t('REMOVE')}}</el-button>
					</template>
				</el-table-column>
			</el-table>-->
		</basic-container>
	</div>
</template>
<script>
import {tokenList,addToken,delToken,userJurisdiction} from '@/api/tokenManagement'
	export default {
		data() {
			return {
				ruleForm: {
					tokenName: '', //tokenName 双向绑定的值
					time: '', // 过期时间
					checkedKeys: [],
				},
                tokenId: '', // token值
                tableData: [], //表结构
				radio: '1',
				radios: '',
				visity: true,
				check: {},
				checkedCities: [4],
				radioCities: [],
				cities: [{ id: 1, name: '个人' }
					, { id: 2, name: '部门' }
					, { id: 4, name: '公司' }
					, { id: 8, name: '集团/品牌' }],
				checked1: 1,
				ckList: {
					checkList: [],
					radios: [{ id: 1, name: '拥有' }
						, { id: 2, name: '授予' }
						, { id: 4, name: '可授予授予' }
						, { id: 8, name: '拒绝' }],
				},
				checked2: 2,
				checked3: 4,
				checked4: 8,
				strArray: '',
				str: '',
				checkId: '4',
				radioId: '0',
				saveList: [],
				// 树结构数据
				data: [],
				defaultProps: {
					children: 'children',
					label: 'label'
				},
                page: {
                    currentPage: 1,
                    total: 0,
                    layout: "total,pager,prev, next",
                    background: true,
                    pageSize: 10
                },
                tableOpt: {
                    page: false,
                    refreshBtn: false,
                    columnBtn: false,
                    simplePage: false,
                    addBtn: false,
                    addRowBtn: false,
                    editBtn: false,
                    delBtn: false,
                    cellBtn: false,
                    index: true,
					indexLabel: ' ',
				    menuTitle: this.$t('OPERATION'),
                    border: true,
                    align: "center",
                    emptyText: this.$t('No Data'),
                    tip: false,
                    column: [
                        {
                            label: this.$t('TOKEN NAME'),
                            prop: 'tokenName',
                        },
                        {
                            label: this.$t('EXPIRATION TIME'),
							prop: 'expireTime',
                            type: "date",
                            format: 'yyyy-MM-dd',
                            valueFormat: 'yyyy-MM-dd',
                        },
                        {
                            label: this.$t('CREATION TIME'),
							prop: 'createTime',
                            type: "date",
                            format: 'yyyy-MM-dd HH:mm:ss',
                            valueFormat: 'yyyy-MM-dd HH:mm:ss',
                        }
                    ]
				},
                rules: {
                    tokenName: [
                        { required: true, message: this.$t('Required'), trigger: 'blur' },
                    ],
                    checkedKeys: [
                        { type: 'array', required: true, message: this.$t('Please select at least one'), trigger: 'change'}
                    ],
                }
			};
		},
		created() {
			//列表
			this.list()
			//权限
			this.getUserJurisdiction()
		},
		methods: {
            rowStyle({ rowIndex }) {
                if (rowIndex % 2 === 0) {
                    return {
                        backgroundColor: '#F8F8F8'
                    }
                }
			},
            currentChange(val) {
                this.page.currentPage = val
                this.list();
			},
            //删除数据
			del(row) {
				this.$confirm(this.$t('IsDel'), this.$t('Tips'), {
					confirmButtonText: this.$t('Yes'),
					cancelButtonText: this.$t('Cancel'),
					type: "warning"
				}).then(() => {
                    delToken(row.tokenId).then(() => {
						this.list();
						this.$message({ showClose: true, message: this.$t('DeleteSuccess'), type: "success" });
					});
				});
			},
			// 获取token列表
			list() {
				tokenList('').then(res => {
					this.tableData = res.data
				})
			},
			handleEdit(index, row) {
				delToken(row.tokenId).then(res => {
					this.$message.success('删除成功')
					this.list()
				})
			},
			save() {
				this.strArray = this.str + ";" + this.checkId + ';' + this.radioId
				this.saveList.push(this.strArray)
			},
			//获取token
            getToken(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
						this.strArray = this.str + ";" + this.checkId + ';' + this.radioId;
						if (this.ruleForm.time == '')
						{
							this.ruleForm.time = null;
						}
                        addToken(null, this.ruleForm.tokenName, this.ruleForm.time, null, null, this.ruleForm.checkedKeys, this.saveList).then(res => {
                            this.tokenId = res.data.result
                            this.list()
                        })
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });

			},
			// 获取权限
			getUserJurisdiction() {
				userJurisdiction('').then(res => {
					this.data = res.data
				})
			},
			// 选中的节点
			clickCheck(currentObj, treeStatus) {
				this.str = currentObj.id
				this.check = currentObj
                this.ruleForm.checkedKeys = treeStatus.checkedKeys
			},
			checkRow(checked, item) {
				this.checkId = eval(checked.join("+"))
			},
			changeCheckbox(e, item) {
				this.radioId = eval(e.join('+'))
				if (this.radioId == undefined) {
					return ''
				}
				const val = item[item.length - 1].id;
				if (e[e.length - 1] === val) {
					e.splice(0, e.length - 1)
				}
			},
			setCheckedKeys(keys, leafOnly) {
				console.log(keys.checkedNodes.label, leafOnly);
			},
			nodeCollapse(key, value) {
				console.log(key, value);
			}
		}
	};
</script>
 